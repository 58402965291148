/*@tailwind base; !* Zakladne CSS pre pracu roznych prehliadacoch - moze prepisat nieco z antu *!*/

.flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center
}

/* dekorator pre custom css komponenty - apply css1 css2 css3.... */

#tailwind .visible {
  visibility: visible
}

#tailwind .invisible {
  visibility: hidden
}

#tailwind .static {
  position: static
}

#tailwind .fixed {
  position: fixed
}

#tailwind .float-right {
  float: right
}

#tailwind .m-0 {
  margin: 0px
}

#tailwind .m-2 {
  margin: 0.5rem
}

#tailwind .m-1 {
  margin: 0.25rem
}

#tailwind .mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

#tailwind .my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
}

#tailwind .my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem
}

#tailwind .mb-1 {
  margin-bottom: 0.25rem
}

#tailwind .ml-5 {
  margin-left: 1.25rem
}

#tailwind .mt-6 {
  margin-top: 1.5rem
}

#tailwind .mr-6 {
  margin-right: 1.5rem
}

#tailwind .ml-3 {
  margin-left: 0.75rem
}

#tailwind .ml-2 {
  margin-left: 0.5rem
}

#tailwind .mt-2 {
  margin-top: 0.5rem
}

#tailwind .mb-2 {
  margin-bottom: 0.5rem
}

#tailwind .ml-1 {
  margin-left: 0.25rem
}

#tailwind .mr-2 {
  margin-right: 0.5rem
}

#tailwind .mt-20 {
  margin-top: 5rem
}

#tailwind .mt-3 {
  margin-top: 0.75rem
}

#tailwind .mt-5 {
  margin-top: 1.25rem
}

#tailwind .mb-4 {
  margin-bottom: 1rem
}

#tailwind .mr-3 {
  margin-right: 0.75rem
}

#tailwind .mr-4 {
  margin-right: 1rem
}

#tailwind .ml-4 {
  margin-left: 1rem
}

#tailwind .ml-6 {
  margin-left: 1.5rem
}

#tailwind .mt-4 {
  margin-top: 1rem
}

#tailwind .mr-5 {
  margin-right: 1.25rem
}

#tailwind .mt-1 {
  margin-top: 0.25rem
}

#tailwind .mr-8 {
  margin-right: 2rem
}

#tailwind .block {
  display: block
}

#tailwind .inline-block {
  display: inline-block
}

#tailwind .inline {
  display: inline
}

#tailwind .flex {
  display: -webkit-flex;
  display: flex
}

#tailwind .table {
  display: table
}

#tailwind .grid {
  display: grid
}

#tailwind .h-10 {
  height: 2.5rem
}

#tailwind .h-px {
  height: 1px
}

#tailwind .h-auto {
  height: auto
}

#tailwind .h-full {
  height: 100%
}

#tailwind .w-full {
  width: 100%
}

#tailwind .w-60 {
  width: 15rem
}

#tailwind .w-1\/2 {
  width: 50%
}

#tailwind .w-max {
  width: -webkit-max-content;
  width: max-content
}

#tailwind .w-16 {
  width: 4rem
}

#tailwind .min-w-full {
  min-width: 100%
}

#tailwind .max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content
}

#tailwind .max-w-full {
  max-width: 100%
}

#tailwind .table-fixed {
  table-layout: fixed
}

#tailwind .border-collapse {
  border-collapse: collapse
}

#tailwind .grid-flow-row {
  grid-auto-flow: row
}

#tailwind .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

#tailwind .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

#tailwind .grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr))
}

#tailwind .flex-row-reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse
}

#tailwind .flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap
}

#tailwind .place-content-stretch {
  place-content: stretch
}

#tailwind .content-end {
  -webkit-align-content: flex-end;
          align-content: flex-end
}

#tailwind .items-center {
  -webkit-align-items: center;
          align-items: center
}

#tailwind .justify-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start
}

#tailwind .justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end
}

#tailwind .justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

#tailwind .justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

#tailwind .justify-items-center {
  justify-items: center
}

#tailwind .gap-2 {
  gap: 0.5rem
}

#tailwind .gap-5 {
  gap: 1.25rem
}

#tailwind .rounded-full {
  border-radius: 9999px
}

#tailwind .rounded {
  border-radius: 0.25rem
}

#tailwind .rounded-sm {
  border-radius: 0.125rem
}

#tailwind .border {
  border-width: 1px
}

#tailwind .bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity))
}

#tailwind .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

#tailwind .object-fill {
  object-fit: fill
}

#tailwind .p-1 {
  padding: 0.25rem
}

#tailwind .p-2 {
  padding: 0.5rem
}

#tailwind .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

#tailwind .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

#tailwind .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

#tailwind .pl-8 {
  padding-left: 2rem
}

#tailwind .pl-2 {
  padding-left: 0.5rem
}

#tailwind .pt-6 {
  padding-top: 1.5rem
}

#tailwind .pl-5 {
  padding-left: 1.25rem
}

#tailwind .pr-8 {
  padding-right: 2rem
}

#tailwind .pr-2 {
  padding-right: 0.5rem
}

#tailwind .text-left {
  text-align: left
}

#tailwind .text-center {
  text-align: center
}

#tailwind .text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

#tailwind .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

#tailwind .text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

#tailwind .font-semibold {
  font-weight: 600
}

#tailwind .font-medium {
  font-weight: 500
}

#tailwind .font-bold {
  font-weight: 700
}

#tailwind .font-normal {
  font-weight: 400
}

#tailwind .italic {
  font-style: italic
}

#tailwind .leading-8 {
  line-height: 2rem
}

#tailwind .leading-6 {
  line-height: 1.5rem
}

#tailwind .text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

#tailwind .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity))
}

#tailwind .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity))
}

#tailwind .text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity))
}

#tailwind .text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity))
}

#tailwind .text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity))
}

#tailwind .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity))
}

#tailwind .text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity))
}

#tailwind .text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity))
}

#tailwind .text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity))
}

#tailwind .filter {
  -webkit-filter: var(--tw-filter);
          filter: var(--tw-filter)
}

#tailwind .transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

/* utilky pre CSS classy - marginy, apdding, colors, spacing... */

#tailwind .group:hover .group-hover\:visible {
  visibility: visible
}
